import React, { useEffect } from "react";
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from "react-bootstrap";
import Techstack from "./Techstack";
import AboutcardOne from "./AboutCardOne";
import AboutCardTwo from "./AboutCardTwo";
import AboutCardFour from "./AboutCardFour";
import aboutImgOne from "../../Assets/factory_1.jpg";
import aboutImgTwo from "../../Assets/factory_2.png";
import bubbles from "../../Assets/bubbles.png";
import basket from "../../Assets/basket-bg.png";
import hanger from "../../Assets/clothes-hanger.png";
import kleanleaf from "../../Assets/kleanleaf.png";

import Tilt from "react-parallax-tilt";
import pdf from "../../Assets/../Assets/klean-leaf-profile.pdf";
import { pdfjs } from "react-pdf";
import Rellax from 'rellax';
import { AiOutlineDownload } from "react-icons/ai";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;



function About() {

  useEffect(() => {
    const rellax = new Rellax('.rellax');
    return () => {
      rellax.destroy();
    };
  }, []);


  return (
    <Container fluid className="about-section">
      <Helmet>
        <title>Klean Leaf - About Us</title>
        <meta name="description" content="Discover Klean Leaf, your trusted laundry detergent sheets manufacturer. Specializing in eco-friendly laundry solutions, we are dedicated to providing high-quality, sustainable laundry sheets. Best laundry sheets factory" />
      </Helmet>
      <div className="background-wrapper">
        <img src={bubbles} alt="laundry detergent strips" className="rellax background1" data-rellax-speed="7" />
        <img src={hanger} alt="laundry detergent strips" className="rellax background2" data-rellax-speed="-4" />
        <img src={basket} alt="laundry detergent strips" className="rellax background4" data-rellax-speed="2" />
        <img src={kleanleaf} alt="laundry detergent strips" className="rellax background5" data-rellax-speed="8" />
      </div>
      <Container>
        <Row>
          <h1>Laundry Detergent Sheets Manufacturer</h1>
          <h2 className="about-title">
            Know Who We Are
            <p>More about us</p>
          </h2>
        </Row>
        <Row className="about-card-section mobile-reverse">
          <Col md={12} className="about-text-section">
            <AboutcardOne />
          </Col>
        </Row>

        <Row className="about-card-section">
          <Col md={6} className="about-img">
            <Tilt>
              <img src={aboutImgTwo} alt="laundry detergent strips manufacturer" className="img-fluid" />
            </Tilt>
            <div className="about-text-section px-4">
              <AboutCardTwo />
            </div>
          </Col>

          <Col md={6} className="about-img margin-extra">
            <Tilt>
              <img src={aboutImgOne} alt="laundry detergent strips" className="img-fluid" />
            </Tilt>
            <div className="about-text-section px-4">
              <AboutCardFour />
            </div>
          </Col>
        </Row>

        <Row className="my-5" style={{ justifyContent: "center" }}>
          <a
            className="pdf-link btn rounded-pill py-md-3 px-md-5 mt-4"
            href={pdf}
            style={{ maxWidth: "350px" }}
            target="_blank"
            rel="noreferrer"
          >
            <AiOutlineDownload />
            &nbsp;Download Klean Leaf Profile
          </a>
        </Row>

        <h2 className="about-heading">About Us</h2>

      <Techstack />
    </Container>
  </Container>
  );
}

export default About;
