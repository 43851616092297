import React from "react";
import Card from "react-bootstrap/Card";

function AboutCardOne() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p className="about-text">With over 14 years of experience in the laundry care industry, we have established ourselves as a leading laundry detergent sheets factory, consistently delivering exceptional products and services. Our commitment to quality and customer satisfaction is reflected in our innovative formulations and reliable supply chain solutions, making us a trusted partner to our clients.
          </p>
          <p className="about-text">
            Our manufacturer, located in Guangzhou, specializes in the
            manufacturing, R&D, and sales of laundry products. Our team has
            access to the latest technology and techniques, ensuring the highest
            quality of our products. Our skilled technicians and engineers are
            dedicated to delivering the best possible results by continuously
            improving our manufacturing processes.
          </p>
          <p className="about-text">
            Our factory, which covers an area of 120,000 square meters, meets
            the FDA's rules for making laundry detergent products safely and correctly
            (GMP - Good Manufacturing Practices). 
          </p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCardOne;
