import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import post1 from '../BlogPostsContent/BlogPost1';
import post2 from '../BlogPostsContent/BlogPost2';
import post3 from '../BlogPostsContent/BlogPost3';
import post4 from '../BlogPostsContent/BlogPost4';
import post5 from '../BlogPostsContent/BlogPost5';
import post6 from '../BlogPostsContent/BlogPost6';
import post7 from '../BlogPostsContent/BlogPost7';
import post8 from '../BlogPostsContent/BlogPost8';
import post9 from '../BlogPostsContent/BlogPost9';
import './BlogPost.scss'; 

const posts = [post1, post2, post3, post4, post5, post6, post7, post8, post9]; 

const BlogPost = () => {
    const { blogId } = useParams();
    const blog = posts.find(post => post.id.toString() === blogId);

    if (!blog) {
        return <div>Blog post not found.</div>;
    }
    return (
        <article className="blog-post-container">
          <Helmet>
              <title>{blog.title}</title>
              <meta name="description" content={blog.metaTags.description} />
              <meta name="keywords" content={blog.metaTags.keywords} />
              <meta name="author" content={blog.metaTags.author} />
              {/* Open Graph tags */}
              <meta property="og:title" content={blog.title} />
              <meta property="og:description" content={blog.metaTags.description} />
              <meta property="og:image" content={blog.imageUrl1} />
              <meta property="og:url" content={`https://kleanleaf.us/blogs/${blog.id}`} />
              <meta property="og:type" content="article" />

              {/* Twitter Card tags */}
              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:title" content={blog.title} />
              <meta name="twitter:description" content={blog.metaTags.description} />
              <meta name="twitter:image" content={blog.imageUrl} />
          </Helmet>

          <header className="blog-post-header">
            <h1 className="blog-post-title">{blog.title}</h1>
            <p className="blog-post-date">{blog.date}</p>
          </header>
          <section className="blog-post-content">
            <img src={blog.imageUrl1} alt="laundry detergent strips manufacturer" className="blog-post-image" />
            <div className="blog-text-content" dangerouslySetInnerHTML={{ __html: blog.content }} />
          </section>
        </article>
    );
};
export default BlogPost;
