import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/home-main.svg";
import singleSheet from "../../Assets/single-sheet.png";
import truckLoad from "../../Assets/truck-load.png";
import oceanImg from "../../Assets/ocean.png";
import logoRing from "../../Assets/ring.png";
import logoLeaf from "../../Assets/leaf.png";
import packageSample from "../../Assets/home-sheets.png";
import Tilt from "react-parallax-tilt";
import {
  AiFillFacebook,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";

function Home2() {
  return (
    <>
    <Container fluid className="home-about-section" id="about-first">
      <Container className="inner-container">
        <Row className="home-about-intro">
          <Col md={4} className="myAvtar">
              <Tilt>
                <img src={myImg} className="img-fluid" alt="laundry detergent strips manufacturer" />
              </Tilt>
            </Col>
          <Col md={4} className="home-about-description center">
            <p className="home-about-body left">
              As a leading laundry detergent sheets manufacturer, Klean Leaf
              offers world-class private label services, guaranteeing
              top-quality products and exceptional service. 
            </p>
          </Col>
          <Col md={4} className="sample-sheet">
            <Tilt>
              <img src={packageSample} className="img-fluid" alt="laundry detergent strips manufacturer" />
            </Tilt>
          </Col>
        </Row>
      </Container>
    </Container>

    <Container fluid className="home-about-section" id="about">
      <Container className="inner-container features">
        <h2 className="home-about-title left">Sheets Features:</h2>
        <Row className="home-about-content">
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={singleSheet} className="img-fluid" alt="laundry detergent strips manufacturer" />
            </Tilt>
          </Col>
          <Col md={5} className="home-about-feature center mt-4">
            <ul>
              <li>Powerful Clean</li>
              <li>Easily Dissolves</li>
              <li>No Mess Cleaning</li>
              <li>Biodegradable Formula</li>
              <li>Laundry Without Plastic Jugs</li>
              <li>For all machines, including HE</li>
              <li>Works with Hot and Cold Water</li>
              <li>No Animal Test</li>
              <li>No Dyes</li>
            </ul>
          </Col>
          <Col md={3}>
          <div class="rotate-logo-wrapper">
            <img src={logoLeaf} className="img-fluid leaf" alt="laundry detergent strips manufacturer" />
            <img src={logoRing} className="img-fluid ring" alt="laundry detergent strips manufacturer" />
          </div>
          </Col>
        </Row>
      </Container>

      <Container className="inner-container compact">
        <h2 className="home-about-title left">Compact Design:</h2>
        <Row className="home-about-content alignCenter">
          <Col md={4} className="home-about-feature center">
            <ul>
              <li><strong>100%</strong> plastic-free packaging</li>
              <li><strong>94%</strong> Less transportation pollution</li>
              <li>Space-saving</li>
              <li>Low-carbon and environmentally friendly</li>
            </ul>
          </Col>
          <Col md={8} className="truckload">
            <Tilt>
              <img src={truckLoad} className="img-fluid" alt="laundry detergent strips manufacturer" />
            </Tilt>
          </Col>
        </Row>
      </Container>

      <div class="carousel-container">
        <div class="carousel-slide">
          <div class="carousel-item">Sustainable</div>
          <div class="carousel-item">Empowering innovations</div>
          <div class="carousel-item">Customization</div>
          <div class="carousel-item">brand identity</div>
          <div class="carousel-item">Bio-degradable</div>
          <div class="carousel-item">Plastic Free</div>
          <div class="carousel-item">Commitment to Excellence</div>
          <div class="carousel-item">Compact design</div>
        </div>
      </div>

      <Container className="responsibility">
        <h2 className="home-about-title about-responsibility">
          Our Responsibility:
        </h2>
        <img src={oceanImg} className="img-fluid" alt="laundry detergent strips manufacturer" />
        <p className="home-about-body left">
          Each year, over 700 million plastic laundry jugs end up in landfills
          and oceans, contributing to a growing environmental crisis. At Klean
          Leaf, we believe in a better way. Our mission is to provide you with a
          powerful, effective laundry solution that reduces plastic waste and
          leaves our planet cleaner for future generations.{" "}
        </p>

        <Row>
          <Col md={12} className="home-about-social center">
            <h2>FIND US ON</h2>
            <p>Feel free to connect with us</p>
            <ul className="home-about-social-links">
              <li className="social-icons center">
                <a
                  href="https://twitter.com/kleanleaf"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.facebook.com/kleanleaf/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillFacebook />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/kleanleaf"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
            <a
              href="mailto:hello@kleanleaf.us"
              style={{ textDecoration: "none", color: "#134b4e" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <p>hello@kleanleaf.us</p>
            </a>
            <a
              href="mailto:kleanleaf@gmail.coms"
              style={{ textDecoration: "none", color: "#134b4e" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <p>kleanleaf@gmail.com</p>
            </a>
            <a
              href="tel:614-906-5129"
              style={{ textDecoration: "none", color: "#134b4e" }}
            >
              <p>614.906.5129</p>
            </a>
          </Col>
        </Row>
      </Container>
    </Container>
    </>
  );
}
export default Home2;
